import { Utils } from "../helpers/utils";

(() => {

  const params = Utils.queryToParams(location.search);
  const filterList = document.querySelector(".filter-form");
  if(!filterList) return;
  const filterCheckboxes = filterList.querySelectorAll('input[type="checkbox"]');
  const allTeaser = document.querySelectorAll(".teaser-list--filterable .teaser_box:not(.teaser_box--login)");
  const noResults = document.querySelector("#no-results");
  let selectedTags = [];
  let filtering = false;

  if(!filterCheckboxes) return;

  filterCheckboxes.forEach(function (filterCheckbox) {
    filterCheckbox.addEventListener("change", function (event) {
      filterCheckboxes.forEach(function (filterCheckbox2) {
        if(filterCheckbox == filterCheckbox2) return;
        filterCheckbox2.checked = false;
      });
      debouncedFilterList();
    });
  });

  filterList.addEventListener("reset", function (event) {
    filterCheckboxes.forEach(function (opt) {
        opt.dispatchEvent(new Event("change"));
      });
  });

  function setFiltersFromHistory(queryParams) {
    const paramList = queryParams.split(",");

    paramList.forEach((element, index) => {
      const inputElement = document.querySelector('[value="' + element + '"]');
      inputElement.checked = true;
      inputElement.parentNode.classList.add("checked");
      inputElement.dispatchEvent(new Event("change"));
    });
  }

  function debouncedFilterList() {
    filtering = true;
    let timeout;
    timeout = setTimeout(() => {
      if (filtering) {
        handleFilterList();
        filtering = false;
      }
    }, 200);
  }

  function handleFilterList() {
    selectedTags = Array.from(filterList.querySelectorAll('input[type="checkbox"]:checked')).map(function (el) {
          return el.value;
    });

    allTeaser.forEach(function (teaser) {
      teaser.classList.add("is-hidden");
      const teaserTagsIds = Array.from(teaser.querySelectorAll("[data-tag-id]")).map(function (teaserTag) {
        return teaserTag.dataset.tagId;
      });
      const tagMatches = selectedTags.every(function (tag) {
          return teaserTagsIds.includes(tag);
      });
      if (tagMatches) {
        teaser.classList.remove("is-hidden");
      }
    });

    handleNoResults();
    handleHistory();
  }

  function handleNoResults() {
    if (noResults !== null) {
      if (document.querySelectorAll(".teaser-list--filterable .teaser_box.is-hidden:not(.teaser_box--login)").length === allTeaser.length) {
        noResults.classList.remove("is-hidden");
      } else {
        noResults.classList.add("is-hidden");
      }
    }
  }

  function handleHistory() {
    const params = Utils.queryToParams(location.search);
    
    if (!params["filterTags"] || params["filterTags"].length !== selectedTags.length) {
      if (selectedTags.length) {
        params["filterTags"] = selectedTags;
      } else {
        delete params["filterTags"];
      }
      history.pushState(null, null, location.origin + location.pathname + Utils.paramsToQuery(params) + location.hash);
    }
  }

  if (params["filterTags"]) {
    setFiltersFromHistory(params["filterTags"]);
  }
})();
